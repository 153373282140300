import React ,{ Component }from "react";
import { FiLayers , FiUsers , FiCode, FiDatabase, FiPieChart, FiPackage } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiCode />,
        title: 'Software Development',
        description: 'Large experience in running and improving software that meets user needs.'
    },
    {
        icon: <FiDatabase />,
        title: 'Data Pipelines',
        description: 'Designed and engineered reliable and scalable ML/BI Infrastructure.'
    },
    { 
        icon: <FiPackage/>,
        title: 'Machine Learning Development',
        description: 'Lead the development of multiple ML models over multiple companies.'
    },
    {
        icon: <FiLayers />,
        title: 'APIs Development',
        description: 'Created APIs for many companies, including bank and recruiting industries.'
    },
    {
        icon: <FiPieChart />,
        title: 'BI & Data Analytics',
        description: 'Created charts and dashboards to provide critical information in many scenarios.'
    },
    { 
        icon: <FiUsers />,
        title: 'Mentoring',
        description: 'Mentored junior developers, helping them to follow best practices.'
    }
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row service-main-wrapper">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            <div className="service service__style--2 text-left bg-gray">
                                <div className="icon">
                                    {val.icon}
                                </div>
                                <div className="content">
                                    <h3 className="title">{val.title}</h3>
                                    <p>{val.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
