import React, { Component } from "react";

const Portfolio_image = <img src="/assets/images/portfolio/portfolio-4.jpg" alt="Stable Diffusion" />;
const Portfolio_image2 = <img src="/assets/images/portfolio/portfolio-5.jpg" alt="Dataflow Tutorial" />;
const Portfolio_image3 = <img src="/assets/images/portfolio/portfolio-6.jpg" alt="Random ML Solutions" />;

const PortfolioListContent = [
    {
        image: Portfolio_image,
        category: 'Machine Learning',
        title: 'Stable Diffusion',
        description: 'A simple example of stable diffusion models.',
        buttonLink: 'https://github.com/vrcadore/image-generation-example'
    },
    {
        image: Portfolio_image2,
        category: 'Data Enginner',
        title: 'Dataflow Tutorial',
        description: 'A minimalist tutorial on GCP Dataflow.',
        buttonLink: 'https://github.com/vrcadore/apache_beam_examples'
    },
    {
        image: Portfolio_image3,
        category: 'Machine Learning',
        title: 'ML Solutions',
        description: 'A bunch of random Kaggle solutions.',
        buttonLink: 'https://github.com/vrcadore/learning_topics'
    }
]

class PortfolioList extends Component{
    render(){
        const {column , styevariation } = this.props;
        const list = PortfolioListContent.slice(0 , this.props.item);
        return(
            <React.Fragment> 
                {list.map((value , index) => (
                    <div className={`${column}`} key={index}>
                        <div className={`im_portfolio ${styevariation}`}>
                            <div className="thumbnail_inner">
                                <div className="thumbnail">
                                    <a href={`${value.buttonLink}`}>
                                        {value.image}
                                    </a> 
                                </div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <div className="portfolio_heading">
                                        <div className="category_list">
                                            <a href={`${value.buttonLink}`}>{value.category}</a>
                                        </div>
                                        <h4 className="title"><a href={`${value.buttonLink}`}>{value.title}</a></h4>
                                    </div>
                                    <div className="portfolio_hover">
                                        <p>{value.description}</p>
                                    </div>
                                </div>
                                <a className="transparent_link" href={`${value.buttonLink}`}></a>
                            </div> 
                        </div>
                    </div>
                ))}
               
            </React.Fragment>
        )
    }
}
export default PortfolioList;