import React, { Component } from "react";

class BrandTwo extends Component{
    render(){
        return(
            <React.Fragment>
                <ul className="brand-style-2">
                    <li>
                        <a href="https://www.bt.com/">
                            <img src="/assets/images/brand/brand-1.png" alt="BT"/>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.reed.co.uk/">
                            <img src="/assets/images/brand/brand-3.png" alt="reed.co.uk"/>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.hpe.com/">
                            <img src="/assets/images/brand/brand-4.png" alt="HP"/>
                        </a>
                    </li>
                    <li>
                        <a href="https://ceracare.co.uk/">
                            <img src="/assets/images/brand/brand-2.png" alt="CeraCare"/>
                        </a>
                    </li>
                    <li>
                        <a href="http://www.mobiltec.com.br/">
                            <img src="/assets/images/brand/brand-5.png" alt="Mobiltec"/>
                        </a>
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}
export default BrandTwo;