import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { ProgressBar } from 'react-bootstrap';

class TabsTwo extends Component{
    render(){
        let 
        tab1 = "Main skills",
        tab2 = "Programing",
        tab3 = "Certifications",
        tab4 = "Education";
        const { tabStyle } = this.props
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                        <Tab>{tab3}</Tab>
                                        <Tab>{tab4}</Tab>
                                    </TabList>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <div className={`rn-progress-bar progress-bar--1 mt_dec--10`}>
                                                <div className="single-progress">
                                                    <h6 className="title">Software Engineering</h6>
                                                    <ProgressBar now={100} />
                                                </div>

                                                <div className="single-progress">
                                                    <h6 className="title">Machine Learning</h6>
                                                    <ProgressBar now={100} />
                                                </div>

                                                <div className="single-progress">
                                                    <h6 className="title">Data Engineering</h6>
                                                    <ProgressBar now={100} />
                                                </div>

                                                <div className="single-progress">
                                                    <h6 className="title">DevOps & Cloud Infrastructure</h6>
                                                    <ProgressBar now={100} />
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <div className={`rn-progress-bar progress-bar--1 mt_dec--10`}>
                                                <div className="single-progress">
                                                    <h6 className="title">Python</h6>
                                                    <ProgressBar now={100} />
                                                </div>

                                                <div className="single-progress">
                                                    <h6 className="title">C#</h6>
                                                    <ProgressBar now={100} />
                                                </div>

                                                <div className="single-progress">
                                                    <h6 className="title">Javascript</h6>
                                                    <ProgressBar now={100} />
                                                </div>

                                                <div className="single-progress">
                                                    <h6 className="title">C++</h6>
                                                    <ProgressBar now={100} />
                                                </div>
                                            </div>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a href="/service">Professional Machine Learning Engineer<span> - Google</span></a> 2022 - 2024
                                               </li>
                                               <li>
                                                   <a href="/service">Professional Data Engineer<span> - Google</span></a> 2022 - 2024
                                               </li>
                                               <li>
                                                   <a href="/service">Certified Machine Learning<span> - AWS </span></a> 2022 - 2024
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a href="/service">B. Sc. in Computer Science<span> - UFRGS, Brazil</span></a> 2010
                                               </li>
                                               <li>
                                                   <a href="/service">Deep Learning<span> - Coursera</span></a> 2018
                                               </li>
                                               <li>
                                                   <a href="/service">Natural Language Processing<span> - Coursera</span></a> 2020
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>
                                    
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsTwo;